import { GET_TIME_SLOTS, CHECK_RESERVATION, SAVE_BILLING_DETAILS, FETCH_ALL_RESERVATIONS, LOAD_RESERVATION_BY_ID } from '../Types';

export const fetchTimeSlots = (data) => {
    return {
        type: GET_TIME_SLOTS,
        data
    };
};

export const reservationCheck = (data) => {
    return {
        type: CHECK_RESERVATION,
        data
    };
};

export const saveBillingDetails = (data) => {
    return {
        type: SAVE_BILLING_DETAILS,
        data
    };
};

export const fetchAllReservations = (data) => {
    return {
        type: FETCH_ALL_RESERVATIONS,
        data
    };
};

export const loadReservationByID = (data) => {
    return {
        type: LOAD_RESERVATION_BY_ID,
        data
    };
};