import React, { Component } from "react";

import GoogleAnalytics from "react-ga4";

const withTracker = (WrappedComponent, options = {}) => {
	const trackPage = page => {
		// if (localStorage.getItem("enableGoogleAnalytics") === "true") {
		// 	GoogleAnalytics.initialize(localStorage.getItem("googleAnalyticsId"));
		// 	GoogleAnalytics.set({
		// 		page,
		// 		...options
		// 	});
		// 	GoogleAnalytics.pageview(page);
		// } else {
			GoogleAnalytics.initialize("G-K6NJZMGC3F");
            GoogleAnalytics.set({
                page,
                ...options,
            });
			GoogleAnalytics.send({ hitType: "pageview", page: page });
		// }
	};

	const HOC = class extends Component {
		componentDidMount() {
			const page = this.props.location.pathname;
			trackPage(page);
		}

		componentWillReceiveProps(nextProps) {
			const currentPage = this.props.location.pathname;
			const nextPage = nextProps.location.pathname;

			if (currentPage !== nextPage) {
				trackPage(nextPage);
			}
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return HOC;
};

export default withTracker;
