import React from "react";
import "./header.css";
import logo from "../../Assets/Images/twbyellow.png";
import { Link } from "react-router-dom";
import { CustomButton } from "../../Components/Buttons/formButton";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";

export const Header = () => {
    const user_details = JSON.parse(localStorage.getItem("user_details")) || {};
    const isLoggedIn = user_details.isLoggedIn || false;
    return (
        <div className="header">
            <div className="logo-wrapper">
                <Link to={isLoggedIn ? "/" : "/login"}>
                    <img
                        className="logo"
                        src={logo}
                        draggable={false}
                        alt="logo"
                    />
                </Link>
                {isLoggedIn ? (
                    <Link to="/account/reservations">
                        <CustomButton
                            isPrimary={true}
                            size="small"
                            className="primary-button border border-radius"
                            label={
                                window.innerWidth > 640 ? (
                                    "MY ACCOUNT"
                                ) : (
                                    <PersonOutlinedIcon />
                                )
                            }
                        />
                    </Link>
                ) : null}
            </div>
        </div>
    );
};
