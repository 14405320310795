import React from "react";
import {Grid} from "@material-ui/core";
import {CustomButton} from "../../Components/Buttons/formButton";

export const ReservationCard = ({
                                    reservation_id,
                                    outlet_name,
                                    total,
                                    time_slot,
                                    reservation_date,
                                    no_of_seats,
                                    unique_reservation_id,
                                    reservation_status,
                                    viewReservation,
                                    cancelReservation,
                                    getInvoice,
                                    is_cancellable,
                                    is_invoiceable,
                                }) => {
    return (
        <>
            <div className="reservation-card">
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <span className="reservation-card-details bold-text">
                            {`Booking #: ${unique_reservation_id}`}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={6} className="text-right">
                        <span className="reservation-card-details bold-text">{`₹${Number(
                            total
                        )}`}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <span className="reservation-card-details">
                            {outlet_name}
                        </span>
                        <hr/>
                    </Grid>
                    <Grid item xs={12}>
                        <span className="reservation-card-details">{`Number of seats: ${no_of_seats}`}</span>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <span className="reservation-card-details bold-text">
                            {reservation_date} \ {time_slot}
                        </span>
                    </Grid>
                    <Grid item xs={12} sm={6} className="text-right">
                        <span className="reservation-card-details status">
                            {reservation_status}
                        </span>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item>
                            <div className="reservations-button">
                                <CustomButton
                                    isPrimary={true}
                                    size="large"
                                    className="primary-button border"
                                    label="VIEW DETAILS"
                                    onClick={() =>
                                        viewReservation(unique_reservation_id)
                                    }
                                />
                            </div>
                        </Grid>
                        {is_cancellable && (
                            <Grid item className="btn-grid">
                                <div className="reservations-button">
                                        <CustomButton
                                            isPrimary={true}
                                            size="large"
                                            className="secondary-button border"
                                            label="CANCEL"
                                            onClick={() => cancelReservation(
                                                unique_reservation_id
                                            )
                                            }
                                        />
                                </div>
                            </Grid>
                        )}
                        {is_invoiceable && (
                            <Grid item className="btn-grid">
                                <div className="reservations-button">
                                    <CustomButton
                                        isPrimary={true}
                                        size="large"
                                        className="secondary-button border"
                                        label="GET INVOICE"
                                        onClick={() => getInvoice(
                                            unique_reservation_id
                                        )
                                        }
                                    />
                                </div>
                            </Grid>
                        )}

                    </Grid>
                </Grid>
            </div>
            <br/>
        </>
    );
};
