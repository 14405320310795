import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "./select.css";

export const CustomSelect = ({
  className = "",
  label = "",
  selectFieldClass = "",
  required = false,
  value = "",
  id = "",
  onChange = () => {},
  options = [],
  errors = [],
  errorClassName = "",
  hideDefaultOption = false,
}) => {
  return (
    <div className={`form-field ${className}`}>
      <label htmlFor={id} id={`id-${id}`}>
        <span>
          {label}
          {required ? <sup>*</sup> : ""}
        </span>
      </label>
      <Select
        id={id}
        className={selectFieldClass}
        value={value}
        onChange={onChange}
      >
        {!hideDefaultOption && <MenuItem value={""}>Select</MenuItem>}
        {options.map((option, index) => (
          <MenuItem key={index} value={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
      {errors.map((error, index) => (
        <span className={`error-message ${errorClassName}`} key={index}>
          {error}
        </span>
      ))}
    </div>
  );
};
