import React from "react";
import Button from "@material-ui/core/Button";

export const CustomButton = ({
  className = "",
  isPrimary = true,
  size = "large",
  label = "",
  disabled = false,
  onClick = () => {},
  style = false,
}) => {
  return (
    <Button
      variant={isPrimary ? "contained" : "outlined"}
      size={size}
      className={className}
      disabled={disabled}
      onClick={onClick}
      style={style ? style : {}}
    >
      {label}
    </Button>
  );
};
