import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import './buttons.css';

export const MenuButton = ({value = 0, callBack = () => {}, disabled = false}) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        setCount(value);
    }, [value]);

    const changeCount = event => {
        let newCount = 0;
        if(event === 'ADD') {
            newCount = count + 1;
        } else {
            newCount = count > 0 ? count - 1 : 0;
        }
        callBack(newCount);
    };
    return (
        count === 0 ?
        <Button className='add-button' variant='outlined' disabled={disabled} onClick={() => changeCount('ADD')}>ADD</Button>
        :
        <ButtonGroup size="small" aria-label="small outlined button group">
            <Button className='group-button' disabled={disabled} onClick={() => changeCount('MINUS')}><RemoveOutlinedIcon/></Button>
            <Button className='group-button center-group-button' onClick={null}>{count}</Button>
            <Button className='group-button right-group-button' disabled={disabled} onClick={() => changeCount('ADD')}><AddOutlinedIcon/></Button>
        </ButtonGroup>
    );
}