import React, {useState, useEffect} from "react";
import "./myaccount.css";
import {Grid} from "@material-ui/core";
import {CustomButton} from "../../Components/Buttons/formButton";
import {CustomInput} from '../../Components/Inputs/textField';
import {ReservationCard} from "../../Components/ReservationCard";
import {ListItem} from "../../Components/ListItems";
import {useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {fetchAllReservations} from "../../Actions";
import {request} from "../../Utils";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {useSnackbar} from 'notistack';
import Slide from '@material-ui/core/Slide';

const fields = {
    house: "FLAT NO./BUILDING",
    street: "STREET NAME",
    address: "ADDRESS",
    city: "CITY",
    pincode: "PINCODE",
    dob: "DATE OF BIRTH",
    anniversary_date: "ANNIVERSARY DATE",
};

export const MyAccount = () => {
    let userInfo = JSON.parse(localStorage.getItem('user_info')) || {};
    const allReservations = useSelector((state) => state.reservationsList);
    const dispatch = useDispatch();
    let history = useHistory();
    const [activeTab, setActiveTab] = useState('');
    const [formState, setFormState] = useState({
        name: "",
        email: "",
        phone: "",
        house: "",
        street: "",
        address: "",
        city: "",
        pincode: "",
        dob: "",
        anniversary_date: "",
    });
    const [open, setOpen] = useState(false);
    const [uniqueResID, setUniqueResID] = useState('');
    const [cancelReason, setCancelReason] = useState('');
    const [reasonError, setReasonError] = useState([]);
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        setFormState({
            name: userInfo.name || "",
            email: userInfo.email || "",
            phone: userInfo.phone || "",
            house: userInfo.house || "",
            street: userInfo.street || "",
            address: userInfo.address || "",
            city: userInfo.city || "",
            pincode: userInfo.pincode || "",
            dob: userInfo.dob || "",
            anniversary_date: userInfo.anniversary_date || "",
        });
        getReservations();
    }, []);

    const getReservations = () => {
        setLoading(true);
        request("/reservations", "get", {}, true)
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        let data = res.data.data;
                        dispatch(fetchAllReservations(data));
                    }
                } else {
                    enqueueSnackbar(res, {
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                        TransitionComponent: Slide,
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false
                    });
                }
            });
    };

    useEffect(() => {
        setActiveTab(history.location.pathname);
    }, [history.location.pathname]);

    const logoutUser = () => {
        localStorage.removeItem('user_details');
        localStorage.removeItem("user_info");
        window.location.href = '/login';
    };

    const onTabChange = (tab) => {
        setLoading(true);
        setActiveTab(tab);
        history.push(tab);
        if (tab === "/account/reservations") {
            request("/reservations", "get", {}, true)
                .then((res) => {
                    setLoading(false);
                    if (res.status === 200) {
                        if (res.data.code === 200) {
                            let data = res.data.data;
                            dispatch(fetchAllReservations(data));
                        }
                    } else {
                        enqueueSnackbar(res, {
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                            TransitionComponent: Slide,
                            variant: 'error',
                            preventDuplicate: true
                        });
                    }
                })
                .catch((err) => console.log(err));
        } else {
            setLoading(false);

        }
    };

    const viewReservation = (id) => {
        history.replace(`/account/reservations/${id}`);
    };

    const cancelReservation = () => {
        if (cancelReason !== '') {
            request(`/reservations/${uniqueResID || ''}/cancel`, 'post', {comments: cancelReason}, true)
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 200 && res.data.success === true) {
                            getReservations();
                        } else {
                            enqueueSnackbar(res.data.message, {
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                TransitionComponent: Slide,
                                variant: 'warning',
                                preventDuplicate: true,
                                persist: false
                            });
                        }
                        setOpen(false);
                        setUniqueResID('');
                        setCancelReason('');
                        setReasonError([]);
                    } else {
                        enqueueSnackbar(res, {
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                            TransitionComponent: Slide,
                            variant: 'error',
                            preventDuplicate: true
                        });
                    }
                });
        } else {
            setReasonError(['Reason is required.']);
        }
    };

    const getInvoice = (id = '') => {
        request(`/reservations/${id}?get_invoice=true`, 'get', {}, true, true)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                        const file = new Blob([res.data], {type: 'application/pdf'});
                        const fileURL = window.URL.createObjectURL(file);
                        window.open(fileURL);
                    }
                } else {
                    enqueueSnackbar(res, {
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                        TransitionComponent: Slide,
                        variant: 'error',
                        preventDuplicate: true
                    });
                }
            });
    };

    return (
        <div className="profile-wrapper">
            <div className="card">
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <h5 className="user-details">{formState.name || "-"}</h5>
                        <h6 className="user-details">{formState.phone || "-"}</h6>
                        <h6 className="user-details">{formState.email || "-"}</h6>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className="logout-button-wrappper">
                            <CustomButton
                                isPrimary={true}
                                size="large"
                                className="primary-button border"
                                label="LOGOUT"
                                onClick={() => logoutUser()}
                            />
                        </div>
                    </Grid>
                </Grid>
                <hr/>
                <div className="profile-tab-wrapper">
                    <Grid container>
                        <Grid item xs={12} sm={3}>
                            <div className="sidebar-menu">
                                <div
                                    className={`list-menu-item${
                                        activeTab === "/account/reservations" ? " active" : ""
                                    }`}
                                    onClick={() => onTabChange("/account/reservations")}
                                >
                                    <span>RESERVATIONS</span>
                                </div>
                                <div
                                    className={`list-menu-item${
                                        activeTab === "/account/user-details" ? " active" : ""
                                    }`}
                                    onClick={() => onTabChange("/account/user-details")}
                                >
                                    <span>BILLING DETAILS</span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <div className="sidebar-content">
                                {activeTab === "/account/reservations" ? (
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <h4 className="user-details">Past Reservations</h4>
                                            <br/>
                                            {loading && <CircularProgress size={24} className="buttonProgress"/>}
                                        </Grid>
                                        <div className="reservation-card-wrapper">
                                            {allReservations.map(reserveItem =>
                                                <ReservationCard
                                                    key={reserveItem.reservation_id}
                                                    reservation_id={reserveItem.reservation_id}
                                                    outlet_name={reserveItem.outlet_name}
                                                    total={reserveItem.total}
                                                    time_slot={`${reserveItem.time_slot.start_time} - ${reserveItem.time_slot.end_time}`}
                                                    reservation_date={reserveItem.reservation_date}
                                                    no_of_seats={reserveItem.no_of_seats}
                                                    unique_reservation_id={reserveItem.unique_reservation_id}
                                                    reservation_status={reserveItem.reservation_status}
                                                    is_cancellable={reserveItem.is_cancellable}
                                                    is_invoiceable={reserveItem.is_invoiceable}
                                                    viewReservation={viewReservation}
                                                    cancelReservation={(id) => {
                                                        setOpen(true);
                                                        setUniqueResID(id);
                                                    }}
                                                    getInvoice={(id) => getInvoice(id)}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                ) : (
                                    <>
                                        {Object.entries(fields).map(([key, label], index) => {
                                            return <ListItem key={index} label={label} value={formState[key]}/>;
                                        })}
                                    </>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Dialog
                disableBackdropClick={true}
                open={open}
                fullWidth={true}
                onClose={() => {
                    setOpen(false);
                    setUniqueResID('');
                    setCancelReason('');
                    setReasonError([]);
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle
                    id="max-width-dialog-title">{"Are you sure you want to cancel the reservation?"}</DialogTitle>
                <DialogContent>
                    <CustomInput
                        id="reason"
                        label="Enter Reason"
                        required={true}
                        type="text"
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                        errors={reasonError}
                        errorClassName='default'
                    />
                </DialogContent>
                <DialogActions className='popup-footer'>
                    <CustomButton
                        isPrimary={true}
                        size="large"
                        className="secondary-button border popup-button"
                        label="NO"
                        onClick={() => {
                            setOpen(false);
                            setUniqueResID('');
                            setCancelReason('');
                            setReasonError([]);
                        }}
                    />
                    <CustomButton
                        isPrimary={true}
                        size="large"
                        className="primary-button border popup-button"
                        label="YES"
                        onClick={cancelReservation}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};
