import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import "./viewreservation.css";
import {ListItem} from "../../Components/ListItems";
import {CustomButton} from "../../Components/Buttons/formButton";
import {CustomInput} from '../../Components/Inputs/textField';
import {useSelector, useDispatch} from "react-redux";
import {loadReservationByID} from "../../Actions";
import {request} from "../../Utils";
import {useHistory} from "react-router-dom";
import {useSnackbar} from 'notistack';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export const ViewReservation = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const currentReservation = useSelector((state) => state.viewReservation);
    let uid = history.location.pathname.split("/")[3];
    const {enqueueSnackbar} = useSnackbar();

    const [open, setOpen] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [reasonError, setReasonError] = useState([]);

    useEffect(() => {
        getCurrentReservation();
    }, []);

    const getCurrentReservation = () => {
        request(`/reservations/${uid}`, "get", {}, true)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        let data = res.data.data;
                        dispatch(loadReservationByID(data));
                    }
                } else {
                    enqueueSnackbar(res, {
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                        TransitionComponent: Slide,
                        variant: 'error',
                        preventDuplicate: true
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const getInvoice = (id = "") => {
        request(
            `/reservations/${id}?get_invoice=true`,
            "get",
            {},
            true,
            true
        ).then((res) => {
            if (res.status === 200) {
                if (res.data) {
                    const file = new Blob([res.data], {
                        type: "application/pdf",
                    });
                    const fileURL = window.URL.createObjectURL(file);
                    window.open(fileURL);
                }
            } else {
                enqueueSnackbar(res, {
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    TransitionComponent: Slide,
                    variant: 'error',
                    preventDuplicate: true
                });
            }
        });
    };

    const cancelReservation = (id = "") => {
        if (cancelReason !== "") {
            request(
                `/reservations/${id || ""}/cancel`,
                "post",
                {comments: cancelReason},
                true
            ).then((res) => {
                if (res.status === 200) {
                    if (res.data.code === 200) {
                        setOpen(false);
                        setCancelReason("");
                        setReasonError([]);
                        getCurrentReservation();
                    }
                } else {
                    enqueueSnackbar(res, {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center",
                        },
                        TransitionComponent: Slide,
                        variant: "error",
                        preventDuplicate: true,
                    });
                }
            });
        } else {
            setReasonError(["Reason is required."]);
        }
    };

    return (
        <div className="view-reservation-wrapper">
            <div className="card">
                <Grid item xs={12}>
                    <div className="header-title">
                        <ArrowBackRoundedIcon
                            onClick={() =>
                                history.replace("/account/reservations")
                            }
                            className="header-back-btn"
                        />
                        <h4>{`Booking #${
                            currentReservation.unique_reservation_id || ""
                        }`}</h4>
                    </div>
                </Grid>
                <hr/>
                <div className="booking-details-info">
                    <Grid container>
                        <Grid item xs={12} sm={6} md={4}>
                            <div>BOOKING DATE</div>
                            <h6>
                                {currentReservation.reservation_date}
                            </h6>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div>TIME SLOT</div>
                            <h6>
                                {currentReservation.time_slot
                                    ? `${currentReservation.time_slot.start_time} - ${currentReservation.time_slot.end_time}`
                                    : ""}
                            </h6>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <div>NUMBER OF SEATS</div>
                            <h6>{currentReservation.no_of_seats || 0}</h6>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div>ADDRESS</div>
                            <h6>
                                {currentReservation.address && (
                                    <>
                                        {currentReservation.address.house +
                                        ", " +
                                        currentReservation.address
                                            .street}{" "}
                                        <br/>
                                        {
                                            currentReservation.address.address
                                        }{" "}
                                        <br/>
                                        {currentReservation.address.city +
                                        " - " +
                                        currentReservation.address.pincode}
                                    </>
                                )}
                            </h6>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div>OUTLET</div>
                            <span
                                className="reservation-card-details">{currentReservation.outlet_name}</span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div>STATUS</div>
                            <span
                                className="reservation-card-details status">{currentReservation.reservation_status}</span>
                        </Grid>
                    </Grid>
                </div>
                <div>MENU ITEMS</div>
                <hr/>
                <div className="menu-list-wrapper">
                    {currentReservation.items &&
                    currentReservation.items.map((item) => (
                        <ListItem
                            label={item.name + " x" + item.quantity}
                            value={`₹${Number(item.total || "")}`}
                        />
                    ))}
                </div>
                <hr/>
                <ListItem
                    label="Peak Charges"
                    value={`₹${Number(currentReservation.addn_charges || "")}`}
                />
                <ListItem
                    label="GST"
                    value={`₹${Number(currentReservation.tax || "")}`}
                />
                <Grid container>
                    <Grid item xs={6}>
                        <h5 className="booking-details-info">Total</h5>
                    </Grid>
                    <Grid item xs={6} className="text-right">
                        <h5 className="booking-details-info">{`₹${Number(
                            currentReservation.total || ""
                        )}`}</h5>
                    </Grid>
                </Grid>
                 {(currentReservation.reservation_qr_code && currentReservation.is_invoiceable) ?
                    <span className='qr-code'>
                        <img alt='QR-Code' src={currentReservation.reservation_qr_code || ''}/>
                    </span>
                    :
                    null}
                <Grid container spacing={2}>
                    {currentReservation.is_cancellable && (
                        <Grid item className="btn-grid">
                            <div className="invoice-button">
                                <CustomButton
                                    isPrimary={true}
                                    size="large"
                                    className="secondary-button border"
                                    label={"CANCEL"}
                                    onClick={() => setOpen(true)}
                                />
                            </div>
                        </Grid>
                    )}
                    {currentReservation.is_invoiceable && (
                        <Grid item className="btn-grid">
                            <div className="invoice-button">
                                <CustomButton
                                    isPrimary={true}
                                    size="large"
                                    className="secondary-button border"
                                    label={"GET INVOICE"}
                                    onClick={() => getInvoice(currentReservation.unique_reservation_id)}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </div>
            <Dialog
                disableBackdropClick={true}
                open={open}
                fullWidth={true}
                onClose={() => {
                    setOpen(false);
                    setCancelReason('');
                    setReasonError([]);
                }}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle
                    id="max-width-dialog-title">{"Are you sure you want to cancel the reservation?"}</DialogTitle>
                <DialogContent>
                    <CustomInput
                        id="reason"
                        label="Enter Reason"
                        required={true}
                        type="text"
                        value={cancelReason}
                        onChange={(e) => setCancelReason(e.target.value)}
                        errors={reasonError}
                        errorClassName='default'
                    />
                </DialogContent>
                <DialogActions className='popup-footer'>
                    <CustomButton
                        isPrimary={true}
                        size="large"
                        className="secondary-button border popup-button"
                        label="NO"
                        onClick={() => {
                            setOpen(false);
                            setCancelReason('');
                            setReasonError([]);
                        }}
                    />
                    <CustomButton
                        isPrimary={true}
                        size="large"
                        className="primary-button border popup-button"
                        label="YES"
                        onClick={() => cancelReservation(currentReservation.unique_reservation_id)}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};
