import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Meta(props) {
    return (
        <HelmetProvider>
            <Helmet>
                <title>{props.seotitle}</title>
                <meta name="description" content={props.seodescription}/>
                <meta property="og:type" content={props.ogtype}/>
                <meta property="og:title" content={props.ogtitle}/>
                <meta property="og:description" content={props.ogdescription}/>
                <meta property="og:url" content={props.ogurl}/>
                <meta property="og:site_name" content={props.storeName}/>
                <meta property="og:image" content="/assets/img/social/ogimage.png"/>
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content={props.twittertitle}/>
                <meta name="twitter:description" content={props.twitterdescription}/>
                <meta name="twitter:site" content={`@${props.twitterUsername}`}/>
                <meta name="twitter:image" content="/assets/img/social/twitterimage.png"/>
            </Helmet>
        </HelmetProvider>
    );
}

export default Meta;
