import React from 'react';
import './textField.css';

export const CustomInput = ({
                                className = '',
                                id = '',
                                label = '',
                                required = false,
                                type = 'text',
                                disabled = false,
                                value = '',
                                onChange = () => {
                                },
                                props = {},
                                errors = [],
                                errorClassName = '',
                                min = '',
                                max = ''
                            }) => {
    return (
        <div className={`form-field ${className}`}>
            <label htmlFor={id} id={`id-${id}`}><span>{label}{required ? <sup>*</sup> : ''}</span></label>
            <input className='input-field' id={id} name={id} type={type} disabled={disabled} required={required}
                   value={value}
                   onChange={onChange} min={min} max={max} {...props} autoComplete="off"/>
            {errors.map((error, index) => <span className={`error-message ${errorClassName}`}
                                                key={index}>{error}</span>)}
        </div>
    );
};