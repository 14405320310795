import React, {useEffect, useRef, useState} from "react";
import "./billingdetails.css";
import Grid from "@material-ui/core/Grid";
import { CustomDate } from "../../Components/Date/date.js";
import {CustomInput} from "../../Components/Inputs/textField";
import {Checkbox} from '@material-ui/core';
import {CustomButton} from "../../Components/Buttons/formButton";
import {useDispatch, useSelector} from 'react-redux';
import {saveBillingDetails} from "../../Actions";
import {checkValidation, request, SITE_KEY, PAY_URL} from "../../Utils";
import ReCAPTCHA from 'react-google-recaptcha';
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSnackbar } from 'notistack';
import Slide from '@material-ui/core/Slide';

export const BillingDetails = () => {
    window.onload = () => {
        if (localStorage.getItem('user_info')) {
            if (window.confirm("Refreshing this page will redirect you to the home page. All progress will be lost! Are you sure you want to proceed?")) {
                window.location.href = '/';
            }
        } else {
            window.location.href = '/';
        }
    };
    const recaptchaRef = useRef();
    const dispatch = useDispatch();
    const reservationDetails = useSelector(state => state.reservationDetails);
    const [errors, setErrors] = useState({});
    const [position, setPosition] = useState({
        latitude: 0,
        longitude: 0
    });
    const [isAgreeTermsAndConds, setIsAgreeTermsAndConds] = useState(false);
    let userInfo = JSON.parse(localStorage.getItem('user_info')) || {};
    const [formState, setFormState] = useState({
        reservation_id: reservationDetails.reservation_id || userInfo.reservation_id || "",
        outlet_id: reservationDetails.outlet_id || userInfo.outlet_id || "",
        outlet_name: reservationDetails.outlet_name || userInfo.outlet_name || "",
        unique_reservation_id: reservationDetails.unique_reservation_id || userInfo.unique_reservation_id || "",
        reservation_date: reservationDetails.reservation_date || userInfo.reservation_date || "",
        no_of_seats: reservationDetails.no_of_seats || userInfo.no_of_seats || "",
        time_slot_id: reservationDetails.time_slot_id || userInfo.time_slot_id || "",
        items: reservationDetails.items || userInfo.items || [],
        name: userInfo.name || "",
        email: userInfo.email || "",
        house: userInfo.house || "",
        street: userInfo.street || "",
        address: userInfo.address || "",
        city: userInfo.city || "",
        pincode: userInfo.pincode || "",
        dob: userInfo.dob || null,
        anniversary_date: userInfo.anniversary_date || null
    });
    const [paymentData, setPaymentData] = useState({
        orderId: '',
        // appId: '',
        // customerEmail: '',
        // customerName: '',
        // customerPhone: '',
        // notifyUrl: '',
        // orderAmount: '',
        // orderCurrency: '',
        // orderNote: '',
        // returnUrl: '',
        // signature: ''
    });
    let maxDate = moment().format("YYYY-MM-DD");
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(pos => {
            setPosition({latitude: pos.coords.latitude, longitude: pos.coords.longitude});
        });
    }, []);

    const saveReservation = async () => {
        const token = await recaptchaRef.current.executeAsync();
        if (token !== '') {
            const {name, email, house, street, address, city, pincode, dob, anniversary_date} = formState;
            let errors = checkValidation({
                name: {name: 'Name', value: name, type: 'text', required: true},
                email: {name: 'Email', value: email, type: 'email', required: true},
                house: {name: 'Flat No, Building Name', value: house, type: 'text', required: true},
                street: {name: 'Street Name', value: street, type: 'text', required: true},
                address: {name: 'Address', value: address, type: 'text', required: true},
                city: {name: 'City', value: city, type: 'text', required: true},
                pincode: {name: 'Pincode', value: pincode, type: 'number', required: true},
                dob: {name: 'DOB', value: dob, type: 'date', required: false},
                anniversary_date: {name: 'Anniversary Date', value: anniversary_date, type: 'date', required: false}
            });
            if (Object.keys(errors).length === 0) {
                let payload = {...formState, latitude: position.latitude, longitude: position.longitude};
                setErrors({});
                request('/reservations/store', 'post', payload, true)
                    .then((res) => {
                        if (res.status === 200) {
                            if (res.data.code === 200) {
                                let data = res.data.data;
                                localStorage.setItem('user_info', JSON.stringify({
                                    reservation_id: data.reservation_id || '',
                                    outlet_id: data.outlet_id || '',
                                    outlet_name: data.outlet_name || '',
                                    unique_reservation_id: data.unique_reservation_id || '',
                                    reservation_date: data.reservation_date || '',
                                    no_of_seats: data.no_of_seats || '',
                                    time_slot_id: data.time_slot.id || '',
                                    items: formState.items || [],
                                    tax: data.tax || '',
                                    addn_charges: data.addn_charges || '',
                                    total: data.total || '',
                                    name: data.user.name || '',
                                    phone: data.user.phone || '',
                                    email: data.user.email || '',
                                    house: data.user.default_address ? data.user.default_address.house : '',
                                    street: data.user.default_address ? data.user.default_address.street : '',
                                    address: data.user.default_address ? data.user.default_address.address : '',
                                    city: data.user.default_address ? data.user.default_address.city : '',
                                    pincode: data.user.default_address ? data.user.default_address.pincode : '',
                                    dob: data.user.dob || '',
                                    anniversary_date: data.user.anniversary_date || ''
                                }));
                                dispatch(saveBillingDetails(data));
                                setPaymentData(res.data.payment_data || {});
                                setTimeout(() => {
                                    document.getElementById("payment-form").submit();
                                }, 1000);
                            }
                        } else {
                            enqueueSnackbar(res, {
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                TransitionComponent: Slide,
                                variant: 'error',
                                preventDuplicate: true
                            });
                        }
                    });
            } else {
                setErrors(errors);
            }
        }
    };

    return (
        <div className="billing-form-wrapper">
            {/* <form id="payment-form" method="post" action={PAYMENT_URL}>
                <input type="hidden" name="appId" value={paymentData.appId}/>
                <input type="hidden" name="orderId" value={paymentData.orderId}/>
                <input type="hidden" name="orderAmount" value={paymentData.orderAmount}/>
                <input type="hidden" name="orderCurrency" value={paymentData.orderCurrency}/>
                <input type="hidden" name="orderNote" value={paymentData.orderNote}/>
                <input type="hidden" name="customerName" value={paymentData.customerName}/>
                <input type="hidden" name="customerEmail" value={paymentData.customerEmail}/>
                <input type="hidden" name="customerPhone" value={paymentData.customerPhone}/>
                <input type="hidden" name="returnUrl" value={paymentData.returnUrl}/>
                <input type="hidden" name="notifyUrl" value={paymentData.notifyUrl}/>
                <input type="hidden" name="signature" value={paymentData.signature}/>
            </form> */}
            <form id="payment-form" method="post" action={PAY_URL}>
                <input type="hidden" name="orderId" value={paymentData.orderId}/>
            </form>
            <div className="card">
                <h1 className="billing-title text-center adobe-font">ENTER BILLING DETAILS</h1>
                <br/>
                <ReCAPTCHA size='invisible' ref={recaptchaRef} sitekey={SITE_KEY}/>
                <Grid container>
                    <Grid item xs={6}>
                        <h5 className="heading-title text-left">OUTLET</h5>
                    </Grid>
                    <Grid item xs={6}>
                        <h5 className="heading-title text-right">{`${reservationDetails.outlet_name || userInfo.outlet_name || 0}`}</h5>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <h5 className="heading-title text-left">BOOKING DATE</h5>
                    </Grid>
                    <Grid item xs={6}>
                        <h5 className="heading-title text-right">{(formState.reservation_date || '').replaceAll('-', '/')}</h5>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <h5 className="heading-title text-left">NUMBER OF SEATS</h5>
                    </Grid>
                    <Grid item xs={6}>
                        <h5 className="heading-title text-right">{formState.no_of_seats}</h5>
                    </Grid>
                </Grid>

                {userInfo.addn_charges ? (
                    <Grid container>
                        <Grid item xs={6}>
                            <h5 className="heading-title text-left">PEAK CHARGES</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h5 className="heading-title text-right">{`₹${userInfo.addn_charges}`}</h5>
                        </Grid>
                    </Grid>
                ) : ''}


                <Grid container>
                    <Grid item xs={6}>
                        <h5 className="heading-title text-left">TAX</h5>
                    </Grid>
                    <Grid item xs={6}>
                        <h5 className="heading-title text-right">{`₹${reservationDetails.tax || userInfo.tax || 0}`}</h5>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <h5 className="heading-title text-left">TOTAL</h5>
                    </Grid>
                    <Grid item xs={6}>
                        <h5 className="heading-title text-right">{`₹${reservationDetails.total || userInfo.total || 0}`}</h5>
                    </Grid>
                </Grid>
                {/*<Grid container>*/}
                {/*    <Grid item>*/}
                {/*        <h4 className="text-white text-center adobe-font">Starters and Beverages</h4>*/}
                {/*        <div className="menu-wrapper">*/}
                {/*            {menuItems.map((menuItem) => (*/}
                {/*                <MenuItem menuItem={menuItem} getMenuButtonValues={getMenuButtonValues} onSelectingMenu={onSelectingMenu} />*/}
                {/*            ))}*/}
                {/*        </div>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}

                <br/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            className="field-border"
                            id="name"
                            label="Name"
                            required={true}
                            type="text"
                            value={formState.name}
                            onChange={(e) =>
                                setFormState({...formState, name: e.target.value})
                            }
                            errors={errors.name || []}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            className="field-border"
                            id="email"
                            label="Email"
                            required={true}
                            type="email"
                            value={formState.email}
                            onChange={(e) =>
                                setFormState({...formState, email: e.target.value})
                            }
                            errors={errors.email || []}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            className="field-border"
                            id="house"
                            label="Flat No, Building Name"
                            required={true}
                            type="text"
                            value={formState.house}
                            onChange={(e) =>
                                setFormState({...formState, house: e.target.value})
                            }
                            errors={errors.house || []}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            className="field-border"
                            id="street"
                            label="Street Name"
                            required={true}
                            type="text"
                            value={formState.street}
                            onChange={(e) =>
                                setFormState({...formState, street: e.target.value})
                            }
                            errors={errors.street || []}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <CustomInput
                            className="field-border"
                            id="address"
                            label="Address"
                            required={true}
                            type="text"
                            value={formState.address}
                            onChange={(e) =>
                                setFormState({...formState, address: e.target.value})
                            }
                            errors={errors.address || []}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            className="field-border"
                            id="city"
                            label="City"
                            required={true}
                            type="text"
                            value={formState.city}
                            onChange={(e) =>
                                setFormState({...formState, city: e.target.value})
                            }
                            errors={errors.city || []}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomInput
                            className="field-border"
                            id="pincode"
                            label="Pincode"
                            required={true}
                            type="number"
                            value={formState.pincode}
                            onChange={(e) =>
                                setFormState({...formState, pincode: e.target.value})
                            }
                            props={{
                                min: 0
                            }}
                            errors={errors.pincode || []}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <CustomDate
                            className="field-border"
                            dateFieldClass="custom-date-field"
                            id="dob"
                            label="DOB"
                            required={false}
                            value={formState.dob}
                            maxDate={new Date(maxDate)}
                            onChange={(date) => setFormState({...formState, dob: date})}
                            errors={errors.dob || []}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomDate
                            className="field-border"
                            dateFieldClass="custom-date-field"
                            id="anniversary_date"
                            label="Anniversary Date"
                            required={false}
                            value={formState.anniversary_date}
                            maxDate={new Date(maxDate)}
                            onChange={(date) => setFormState({...formState, anniversary_date: date})}
                            errors={errors.anniversary_date || []}
                        />
                    </Grid>
                </Grid>
                <br/>
                <h5 className="heading-title text-left">TERMS AND CONDITIONS:</h5>
                <ul className='terms-and-conditions'>
                    <li>Please arrive at least 10 minutes prior to your booked slot timing.</li>
                    <li>Late comers strictly will not be allowed to dine-in. If seats are not available in the next
                        slot, they will be given a Premium Pack as Take-Away after paying the difference amount if any
                        from the new take-away bill.
                    </li>
                    <li>No refund or transfer of booking time or date shall be allowed for No-shows. No-shows will not
                        be eligible for any refund.
                    </li>
                    <li>Table arrangements are made similar to a typical Tamil Muslim Wedding (pandhi style) - Customers
                        might have to sit next to an unknown person, but with someone having a common love for Biryani,
                        just like you do!
                    </li>
                    <li>Kids meal will be served only to kids below 90 cm of height. Anyone above this mentioned height
                        will be considered as adult and can only have the Signature Mutton or Chicken Biriyani Packs
                        mentioned above in our menu. In such case, the difference in amount shall be paid by the
                        customer.
                    </li>
                    <li>
                        <strong>Cancellations:</strong> Cancellations made before 12pm the previous day from your booked
                        timeslot will get a 100% refund. Cancellation made after 12pm of the previous day from your
                        booked time slot will not be eligible for any refund. For cancellations, please contact us
                        between 11am – 08pm on +91-9150001646 by Call / WhatsApp.
                    </li>
                    <li>
                        Approved refunds will reach your account within 7 working days from the time of approval.
                    </li>
                </ul>
                <div className='agree-field'>
                    <FormControlLabel
                        htmlFor="terms"
                        className="agree-label"
                        control={
                            <Checkbox name="terms" className='custom-checkbox'
                                      onChange={(e) => setIsAgreeTermsAndConds(e.target.checked)}/>
                        }
                        label="I accept all of the Terms and Conditions"
                    />
                </div>
                <div>
                    <br/>
                    <div className="payment-button">
                        <CustomButton
                            disabled={!isAgreeTermsAndConds}
                            isPrimary={true}
                            size="large"
                            className="primary-button border border-radius"
                            label="CONTINUE TO PAYMENT"
                            onClick={() => saveReservation()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
