import React from "react";
import Grid from "@material-ui/core/Grid";
import './listitem.css';

export const ListItem = ({label = '', value = '-'}) => {
  return (
    <div className="list-item-wrapper">
      <Grid container>
        <Grid className='list-item-left' item xs={6}>
            <h6>{label}</h6>
        </Grid>
        <Grid className='list-item-right' item xs={6}>
            <h6>{value}</h6>
        </Grid>
      </Grid>
    </div>
  );
};
