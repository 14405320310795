import React, {useRef, useState} from "react";
import "./login.css";
import {CustomButton} from "../../Components/Buttons/formButton.js";
import {CustomInput} from "../../Components/Inputs/textField.js";
import {checkValidation, request, SITE_KEY} from "../../Utils";
import ReCAPTCHA from "react-google-recaptcha";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from 'notistack';
import Slide from '@material-ui/core/Slide';

export const Login = () => {
    const recaptchaRef = useRef();
    const [errors, setErrors] = useState({});
    const [activeButton, setActiveButton] = useState(0);
    const [otpMessage, setOtpMessage] = useState("");
    const [formState, setFormState] = useState({
        phone: "+91 ",
        otp: "",
    });
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const sendButton = async (activeState, isResendOtp = false) => {
        setLoading(true);
        const token = await recaptchaRef.current.executeAsync();
        if (token !== "") {
            let phone = formState.phone.replace("+91 ", "");
            let otp = formState.otp.trim();
            let isPhoneNoEntered = checkValidation({
                phone: {
                    name: "Phone",
                    value: phone,
                    type: "text",
                    required: true,
                },
            });
            let isOtpEntered = checkValidation({
                otp: {
                    name: "OTP",
                    value: otp,
                    type: "number",
                    required: true,
                },
            });
            if (
                activeState === 0 &&
                Object.keys(isPhoneNoEntered).length === 0
            ) {
                setActiveButton(1);
                setErrors({});
                let sendOtpUrl = isResendOtp ? "/send-login-otp?resend=true" : "/send-login-otp";
                request(sendOtpUrl, "post", {phone})
                    .then((res) => {
                        setLoading(false);
                        if (res.status === 200) {
                            if (res.data.code === 200) {
                                setOtpMessage(res.data.data || "");
                            }
                        } else {
                            enqueueSnackbar(res, {
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                TransitionComponent: Slide,
                                variant: 'error',
                                preventDuplicate: true
                            });
                        }
                    })
                    .catch((err) => console.log(err));
            } else if (activeState === 0) {
                setLoading(false);
                setErrors(isPhoneNoEntered);
            }
            if (
                activeState === 1 &&
                Object.keys({...isPhoneNoEntered, ...isOtpEntered}).length ===
                0
            ) {
                setErrors({});
                request("/verify-login-otp", "post", {phone, otp})
                    .then((res) => {
                        setLoading(false);
                        if (res.status === 200) {
                            if (res.data.code === 200) {
                                let data = res.data.data;
                                localStorage.setItem(
                                    "user_details",
                                    JSON.stringify({
                                        token: data.auth_token,
                                        isLoggedIn: true,
                                    })
                                );
                                localStorage.setItem('user_info', JSON.stringify({
                                    name: data.name || '',
                                    phone: data.phone || '',
                                    email: data.email || '',
                                    house: data.default_address ? data.default_address.house : '',
                                    street: data.default_address ? data.default_address.street : '',
                                    address: data.default_address ? data.default_address.address : '',
                                    city: data.default_address ? data.default_address.city : '',
                                    pincode: data.default_address ? data.default_address.pincode : '',
                                    dob: data.dob || '',
                                    anniversary_date: data.anniversary_date || ''
                                }));
                                window.location.href = "/";
                            }
                        } else {
                            enqueueSnackbar(res, {
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                },
                                TransitionComponent: Slide,
                                variant: 'error',
                                preventDuplicate: true
                            });
                        }
                    })
                    .catch((err) => alert(err));
            } else if (activeState === 1) {
                setLoading(false);
                setErrors({...isPhoneNoEntered, ...isOtpEntered});
            }
        } else {
            setLoading(false);
            setActiveButton(1);
            alert("An error occurred. Please try again!")
        }
    };

    return (
        <div className="login-wrapper text-center">
            <h1 className="login-title text-center adobe-font">
                BOOK YOUR SEATS
            </h1>
            <div className="title-separator"></div>
            <p className="login-description text-center">
                You can truly experience the traditional taste of the Tamil Muslim Wedding Biryani in the
                wonderful ambiance of our restaurant. We will astound you with all the authentic flavours
                along with the famed Tamil hospitality.
            </p>
            <p className="login-description text-center">
                Enjoy our recreation of a typical Tamil Muslim wedding style dine-in where you would be served in a
                pandhi style. We are not just serving traditional style food but
                a bit of our culture too.
            </p>
            <p className="login-description text-center">
                We also make sure you get the best dishes by employing happy chefs and choosing the
                fresh ingredients. To help us enhance your experience, we request you to make full
                pre-payment to confirm your reservation.
            </p>
            <div className="login-form-wrapper">
                <div className="card">
                    <p className="login-heading">Login to get started</p>
                    <ReCAPTCHA
                        size="invisible"
                        ref={recaptchaRef}
                        sitekey={SITE_KEY}
                    />
                    <CustomInput
                        id="phone"
                        label="PHONE"
                        required={true}
                        type="text"
                        value={formState.phone}
                        onChange={(e) => {
                            setFormState({phone: e.target.value, otp: ""});
                            setActiveButton(0);
                        }}
                        errors={errors.phone || []}
                    />
                    {formState.phone.replace(" ", "").length === 13 &&
                    activeButton ? (
                        <>
                            <CustomInput
                                id="otp"
                                label="ONE TIME PASSWORD"
                                required={true}
                                type="number"
                                value={formState.otp}
                                onChange={(e) =>
                                    setFormState({
                                        ...formState,
                                        otp: e.target.value,
                                    })
                                }
                                errors={errors.otp || []}
                            />
                            {otpMessage !== "" && (
                                <p className="otp-text">{otpMessage}</p>
                            )}
                        </>
                    ) : null}
                    <br/>
                    <CustomButton
                        isPrimary={true}
                        size="large"
                        disabled={loading}
                        className="primary-button border border-radius"
                        label={activeButton === 0 ? "GET OTP" : "LOGIN"}
                        onClick={() => sendButton(activeButton)}
                    />
                    {formState.phone.replace(" ", "").length === 13 && activeButton ? <span className="resend-link" onClick={() => sendButton(0, true)}>Resend OTP</span> : null}
                    {loading && <CircularProgress size={24} className="buttonProgress"/>}
                </div>
            </div>
        </div>
    );
};
