import React from 'react';
import { useHistory } from 'react-router-dom';
import { ListItem } from '../../Components/ListItems';
import { CustomButton } from "../../Components/Buttons/formButton";
import './reservationSuccess.css';
import { useSelector } from 'react-redux';

const fields = {
  reservation_id: 'BOOKING #',
  reservation_date: 'BOOKING DATE',
  no_of_seats: 'NUMBER OF SEATS',
  tax: 'TAX',
  peak_charges: 'PEAK CHARGES',
  total: 'TOTAL',
  name: 'NAME',
  email: 'EMAIL',
  house: 'FLAT NO./BUILDING',
  street: 'STREET NAME',
  address: 'ADDRESS',
  city: 'CITY',
  pincode: 'PINCODE',
  dob: 'DATE OF BIRTH',
  anniversary_date: 'ANNIVERSARY DATE',
};

export const ReservationSuccess = () => {
  let history = useHistory();
  const reservationDetails = useSelector(state => state.reservationDetails);
  const userDetails = useSelector(state => state.userDetails);
  let userInfo = JSON.parse(localStorage.getItem('user_info')) || {};
  const successDetails = {
    reservation_id: reservationDetails.unique_reservation_id || userInfo.unique_reservation_id || '',
    reservation_date: reservationDetails.reservation_date || userInfo.reservation_date || '',
    no_of_seats: reservationDetails.no_of_seats || userInfo.no_of_seats || '',
    peak_charges: `₹${reservationDetails.peak_charges || 0}`,
    tax: `₹${reservationDetails.tax || 0}`,
    total: `₹${reservationDetails.total || userInfo.total || 0}`,
    name: userDetails.name || userInfo.name || '',
    email: userDetails.email || userInfo.email || '',
    house: userDetails.house || userInfo.house || '',
    street: userDetails.street || userInfo.street || '',
    address: userDetails.address || userInfo.address || '',
    city: userDetails.city || userInfo.city || '',
    pincode: userDetails.pincode || userInfo.pincode || '',
    dob: userDetails.dob || userInfo.dob || '',
    anniversary_date: userDetails.anniversary_date || userInfo.anniversary_date || ''
  };

  const goToMyAccount = () => {
    history.push('/account/reservations')
  };
  return (
    <div className='reservation-success-wrapper'>
      <div className='card'>
        <h3 className='reservation-success-title text-center'>
          Your booking has been processed.
        </h3>
        <p className='reservation-success-desc text-center'>
          Please check your mail for the confirmation details.
        </p>
        <br/>
        {Object.entries(fields).map(([key, label]) => {
          return <ListItem key={label} label={label} value={successDetails[key]} />;
        })}
        {/* {reservationDetails.reservation_qr_code ? 
        <div className='qr-code'>
          <img alt='QR-Code' src={reservationDetails.reservation_qr_code || ''}/>
        </div>
        :
        null} */}
        <div className="myaccount-button">
          <CustomButton
            isPrimary={true}
            size="large"
            className="primary-button border border-radius"
            label="GO TO MY ACCOUNT"
            onClick={() => goToMyAccount()}
          />
        </div>
      </div>
    </div>
  );
};
