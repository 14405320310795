import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import "./bookyourseat.css";
import Grid from "@material-ui/core/Grid";
import { CustomDate } from "../../Components/Date/date.js";
import { CustomSelect } from "../../Components/Select/select.js";
import { CustomButton } from "../../Components/Buttons/formButton";
import { request } from "../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchTimeSlots, reservationCheck } from "../../Actions";
import moment from "moment";
import { useSnackbar } from "notistack";
import Slide from "@material-ui/core/Slide";
import MenuItem from "../../Components/MenuItem";
import AddonModal from "./AddonModal";

const seatsList = [
    { key: "1", value: "1" },
    { key: "2", value: "2" },
    { key: "3", value: "3" },
    { key: "4", value: "4" },
    { key: "5", value: "5" },
    { key: "6", value: "6" },
    { key: "7", value: "7" },
    { key: "8", value: "8" },
    { key: "9", value: "9" },
    { key: "10", value: "10" },
    { key: "11", value: "11" },
    { key: "12", value: "12" },
    { key: "13", value: "13" },
    { key: "14", value: "14" },
    { key: "15", value: "15" },
    { key: "16", value: "16" },
    { key: "17", value: "17" },
    { key: "18", value: "18" },
    { key: "19", value: "19" },
    { key: "20", value: "20" },
];

export const BookYourSeat = () => {
    const continueBookingRef = useRef(null);
    let history = useHistory();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const timeSlots = useSelector((state) => state.timeSlots);
    var menuItems = useSelector((state) => state.menuItems);

    const [slotList, setSlotList] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [starters, setStarters] = useState([]);

    const [outletList, setOutletList] = useState([]);
    const [formState, setFormState] = useState({
        outlet_id: "",
        no_of_seats: "",
        reservation_date: null,
        time_slot_id: null,
        items: [],
        starters: [],
    });
    const [addonModal, setAddonModal] = useState(false);

    let momentObj = moment();
    let minDate = momentObj.add(1, "days").format("YYYY-MM-DD");
    let maxDate = momentObj.add(6, "days").format("YYYY-MM-DD");

    useEffect(() => {
        request("/outlets").then((res) => {
            if (res && res.status === 200) {
                let data = res.data.data;
                let outlets = [];
                data.forEach((item) => {
                    outlets.push({ key: item.id, value: item.name });
                });

                setOutletList(outlets);
            } else {
                enqueueSnackbar(res || "Error getting time slots", {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    TransitionComponent: Slide,
                    variant: "error",
                    preventDuplicate: true,
                });
            }
        });
    }, []);

    useEffect(() => {
        if (formState.reservation_date) {
            let payload = {
                reservation_date: formState.reservation_date,
                no_of_seats: formState.no_of_seats,
                outlet_id: formState.outlet_id,
            };
            request("/get-time-slots", "post", payload, true)
                .then((res) => {
                    if (res && res.status === 200) {
                        if (res.data.code === 200) {
                            let data = res.data.data;
                            dispatch(fetchTimeSlots(data));
                        }
                    } else {
                        enqueueSnackbar(res || "Error getting time slots", {
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                            },
                            TransitionComponent: Slide,
                            variant: "error",
                            preventDuplicate: true,
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [formState.reservation_date]);

    useEffect(() => {
        if (timeSlots.length > 0) {
            setSlotList(timeSlots);
        }
    }, [timeSlots]);

    useEffect(() => {
        if (menuItems.length > 0) {
            const biryaniItems = menuItems.filter(
                (item) => item.category_name === "Biryani"
            );
            setMenuList(biryaniItems);
            const starterItems = menuItems.filter(
                (item) => item.category_name !== "Biryani"
            );
            setStarters(starterItems);
        }
    }, [menuItems]);

    const onSelectingMenu = (id, quantity) => {
        // showAddonModal(true);
        let allSelectedItemsQuantity = quantity;
        formState.items.forEach((item) => {
            if (item.id !== id) {
                allSelectedItemsQuantity += item.quantity;
            }
        });
        let checkSeatsAndQuantityEqual =
            Number(formState.no_of_seats) < allSelectedItemsQuantity;
        if (quantity === 0) {
            setFormState({
                ...formState,
                items: formState.items.filter((item) => item.id !== id),
            });
        } else {
            if (!checkSeatsAndQuantityEqual) {
                let isItemAlreadyAdded = formState.items.some(
                    (item) => item.id === id
                );
                if (isItemAlreadyAdded) {
                    let updatedSelectedItems = formState.items.map((item) => {
                        if (item.id === id) {
                            return { id, quantity };
                        }
                        return item;
                    });
                    setFormState({ ...formState, items: updatedSelectedItems });
                } else {
                    setFormState({
                        ...formState,
                        items: [...formState.items, { id, quantity }],
                    });
                }
            } else {
                enqueueSnackbar("Items already added for seats selected", {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    TransitionComponent: Slide,
                    variant: "warning",
                    preventDuplicate: true,
                });
            }
        }
    };

    const onSelectingStarters = (id, quantity) => {
        if (quantity === 0) {
            setFormState({
                ...formState,
                starters: formState.starters.filter((item) => item.id !== id),
            });
        } else {
            let isItemAlreadyAdded = formState.starters.some(
                (item) => item.id === id
            );
            if (isItemAlreadyAdded) {
                let updatedSelectedItems = formState.starters.map((item) => {
                    if (item.id === id) {
                        return { id, quantity };
                    }
                    return item;
                });
                setFormState({ ...formState, starters: updatedSelectedItems });
            } else {
                setFormState({
                    ...formState,
                    starters: [...formState.starters, { id, quantity }],
                });
            }
        }
    };

    const getTotal = () => {
        let totalAmount = 0;
        formState.items.forEach((item) => {
            let menuItemDetails =
                menuList.find((list) => list.id === item.id) || {};
            let itemPrice = Number(menuItemDetails.price);
            totalAmount += itemPrice * item.quantity;
        });
        formState.starters.forEach((item) => {
            let menuItemDetails =
                starters.find((list) => list.id === item.id) || {};
            let itemPrice = Number(menuItemDetails.price);
            totalAmount += itemPrice * item.quantity;
        });
        return totalAmount;
    };

    const getMenuButtonValues = (id) => {
        let value = 0;
        let currentItem = formState.items.find((i) => i.id === id);
        if (currentItem) {
            value = currentItem.quantity;
        }
        return value;
    };

    const getStarterButtonValues = (id) => {
        let value = 0;
        let currentItem = formState.starters.find((i) => i.id === id);
        if (currentItem) {
            value = currentItem.quantity;
        }
        return value;
    };

    const checkReservation = () => {
        const {
            outlet_id,
            reservation_date,
            no_of_seats,
            time_slot_id,
            items,
            starters,
        } = formState;
        if (
            outlet_id &&
            reservation_date &&
            Number(no_of_seats) !== 0 &&
            time_slot_id &&
            items.length > 0
        ) {
            let payload = {
                outlet_id,
                reservation_date,
                no_of_seats,
                time_slot_id,
                items: items.concat(starters),
            };
            request("/reservations/in-process", "post", payload, true)
                .then((res) => {
                    if (res && res.status === 200) {
                        if (res.data.code === 200) {
                            let data = res.data.data;
                            localStorage.setItem(
                                "user_info",
                                JSON.stringify({
                                    outlet_id: data.outlet_id || "",
                                    outlet_name: data.outlet_name || "",
                                    reservation_id: data.reservation_id || "",
                                    unique_reservation_id:
                                        data.unique_reservation_id || "",
                                    reservation_date:
                                        data.reservation_date || "",
                                    no_of_seats: data.no_of_seats || "",
                                    time_slot_id: data.time_slot.id || "",
                                    items: items.concat(starters) || [],
                                    tax: data.tax || "",
                                    addn_charges: data.addn_charges || "",
                                    total: data.total || "",
                                    name: data.user.name || "",
                                    phone: data.user.phone || "",
                                    email: data.user.email || "",
                                    house: data.user.default_address
                                        ? data.user.default_address.house
                                        : "",
                                    street: data.user.default_address
                                        ? data.user.default_address.street
                                        : "",
                                    address: data.user.default_address
                                        ? data.user.default_address.address
                                        : "",
                                    city: data.user.default_address
                                        ? data.user.default_address.city
                                        : "",
                                    pincode: data.user.default_address
                                        ? data.user.default_address.pincode
                                        : "",
                                    dob: data.user.dob || "",
                                    anniversary_date:
                                        data.user.anniversary_date || "",
                                })
                            );
                            dispatch(
                                reservationCheck({
                                    ...data,
                                    time_slot_id,
                                    items,
                                })
                            );
                            history.push(`/billing-details`);
                        }
                    } else {
                        enqueueSnackbar(res, {
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                            },
                            TransitionComponent: Slide,
                            variant: "error",
                            preventDuplicate: true,
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const isButtonDisable = () => {
        let quantity = formState.items.reduce((acc, currItem) => {
            const item = menuList.filter(
                (menuItem) => menuItem.id === currItem.id
            );
            if (item[0].category_name === "Biryani") {
                acc += currItem.quantity;
                return acc;
            }
        }, 0);
        return quantity === 0 || quantity != formState.no_of_seats;
    };

    const checkIsSlotDisable = (slotStatus) => {
        if (slotStatus === 0) {
            return " disabled";
        } else if (slotStatus === 2) {
            return " disabled red";
        }
        return "";
    };

    const showAddonModal = () => setAddonModal(true);

    const hideAddonModal = () => {
        // continueBookingRef.current.scrollIntoView({ behavior: "smooth" });
        setAddonModal(false);
        return;
    };

    const getQuantity = (items) =>
        items.reduce((acc, curr) => acc + curr.quantity, 0);

    useEffect(() => {
        if (
            formState.no_of_seats > 0 &&
            getQuantity(formState.items) == formState.no_of_seats
        ) {
            showAddonModal(true);
        }
    }, [formState.items, formState.no_of_seats]);

    return (
        <div className="booking-form-wrapper">
            <AddonModal
                addonModal={addonModal}
                hideAddonModal={hideAddonModal}
                starters={starters}
                getStarterButtonValues={getStarterButtonValues}
                onSelectingStarters={onSelectingStarters}
            />
            <div className="card">
                <h1 className="booking-title text-center adobe-font">
                    BOOK YOUR SEATS
                </h1>
                <Grid container spacing={2} className="mt-4">
                    <Grid item xs={12} sm={12}>
                        <CustomSelect
                            className="field-border"
                            selectFieldClass="custom-select-field"
                            id="outlet_id"
                            label="SELECT OUTLET"
                            required={true}
                            value={formState.outlet_id}
                            onChange={(e) =>
                                setFormState({
                                    ...formState,
                                    outlet_id: e.target.value,
                                    reservation_date: null,
                                    time_slot_id: "",
                                    items: [],
                                })
                            }
                            options={outletList}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className="book-fields">
                            <CustomSelect
                                className="field-border"
                                selectFieldClass="custom-select-field"
                                id="no_of_seats"
                                label="NUMBER OF SEATS"
                                required={true}
                                value={formState.no_of_seats}
                                onChange={(e) =>
                                    setFormState({
                                        ...formState,
                                        no_of_seats: e.target.value,
                                        reservation_date: null,
                                        time_slot_id: "",
                                        items: [],
                                    })
                                }
                                options={seatsList}
                            />
                        </div>
                        <div className="bookings-message">
                            For bulk bookings, please contact +91- 91 5000 1646
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className="book-fields">
                            <CustomDate
                                className="field-border"
                                dateFieldClass="custom-date-field"
                                id="reservation_date"
                                label="DATE"
                                required={true}
                                disableToolbar={true}
                                value={formState.reservation_date}
                                onChange={(date) =>
                                    setFormState({
                                        ...formState,
                                        reservation_date: date,
                                        time_slot_id: null,
                                        items: [],
                                    })
                                }
                                minDate={new Date(minDate)}
                                maxDate={new Date(maxDate)}
                            />
                        </div>
                    </Grid>
                </Grid>
                <br />
                {formState.no_of_seats &&
                formState.reservation_date &&
                slotList.length > 0 ? (
                    <>
                        <h4 className="slots-title text-center adobe-font mb-4">
                            AVAILABLE TIME SLOTS
                        </h4>
                        <Grid container spacing={2}>
                            {slotList.map((slot) => (
                                <Grid
                                    key={slot.id}
                                    item
                                    sm={3}
                                    xs={6}
                                    className={`slot-wrapper${
                                        [0, 2].includes(
                                            slot.availability_status
                                        )
                                            ? " slot-disabled"
                                            : ""
                                    }${checkIsSlotDisable(
                                        slot.availability_status
                                    )}`}
                                >
                                    <div
                                        className={`time-slot-chip${
                                            formState.time_slot_id === slot.id
                                                ? " active"
                                                : ""
                                        }${checkIsSlotDisable(
                                            slot.availability_status
                                        )}`}
                                        onClick={() =>
                                            setFormState({
                                                ...formState,
                                                time_slot_id: slot.id,
                                                items: [],
                                            })
                                        }
                                    >{`${slot.start_time} - ${slot.end_time}`}</div>
                                </Grid>
                            ))}
                        </Grid>
                    </>
                ) : null}
                <br />
                {formState.time_slot_id && menuList.length > 0 ? (
                    <>
                        <h4 className="text-white text-center adobe-font">
                            MENU
                        </h4>
                        <div
                            className={
                                getQuantity(formState.items) !=
                                formState.no_of_seats
                                    ? "sub-title mb-5"
                                    : "hidden"
                            }
                        >
                            Please select{" "}
                            <span style={{ fontWeight: 700 }}>
                                {formState.no_of_seats} items
                            </span>{" "}
                            to continue booking.
                        </div>
                        <div className="menu-wrapper">
                            {menuList.map((menuItem) => (
                                <MenuItem
                                    key={menuItem.id}
                                    menuItem={menuItem}
                                    getMenuButtonValues={getMenuButtonValues}
                                    onSelectingMenu={onSelectingMenu}
                                />
                            ))}
                        </div>
                        <br />
                        <Grid container>
                            <Grid item>
                                <h4 className="text-white text-center adobe-font">
                                    Starters and Beverages
                                </h4>
                                <div className="menu-wrapper">
                                    {starters.map((menuItem) => (
                                        <MenuItem
                                            key={menuItem.id}
                                            menuItem={menuItem}
                                            getMenuButtonValues={
                                                getStarterButtonValues
                                            }
                                            onSelectingMenu={
                                                onSelectingStarters
                                            }
                                        />
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                        <div className="details-tab">
                            <Grid container>
                                <Grid item sm={6} xs={6}>
                                    <h5 className="slots-title text-left">
                                        TOTAL
                                    </h5>
                                </Grid>
                                <Grid item sm={6} xs={6}>
                                    <h5 className="slots-title text-right">{`₹${getTotal()}`}</h5>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                ) : null}
                <div className="submit-booking-button" ref={continueBookingRef}>
                    <CustomButton
                        disabled={
                            formState.no_of_seats
                                ? formState.time_slot_id
                                    ? isButtonDisable()
                                    : true
                                : true
                        }
                        isPrimary={true}
                        size="large"
                        className="primary-button border border-radius"
                        label="CONTINUE BOOKING"
                        onClick={() => checkReservation()}
                    />
                </div>
            </div>
        </div>
    );
};
