import React from "react";
import {Login} from "../Login";
import {BookYourSeat} from "../BookYourSeat";
import {BillingDetails} from "../BillingDetails";
import {ReservationSuccess} from "../ReservationSuccess";
import {ReservationFailure} from "../ReservationFailure";
import {MyAccount} from "../MyAccount";
import {ViewReservation} from "../ViewReservation";
import {Switch, Route, Redirect} from "react-router-dom";
import Meta from "../../Components/helpers/meta";
import withTracker from "../../withTracker";

export const Desktop = () => {
    const user_details = JSON.parse(localStorage.getItem("user_details")) || {};
    const isLoggedIn = user_details.isLoggedIn || false;
    return (
        <Switch>
            <>
                <Meta
                    seotitle="The Wedding Biryani"
                    seodescription={localStorage.getItem("seoMetaDescription")}
                    ogtype="website"
                    ogtitle={localStorage.getItem("seoOgTitle")}
                    ogdescription={localStorage.getItem("seoOgDescription")}
                    ogurl={window.location.href}
                    twittertitle={localStorage.getItem("seoTwitterTitle")}
                    twitterdescription={localStorage.getItem(
                        "seoTwitterDescription"
                    )}
                />
                {isLoggedIn ? (
                    <>
                        <Route
                            exact
                            path="/"
                            component={withTracker(BookYourSeat)}
                        />
                        <Route
                            exact
                            path="/billing-details"
                            component={withTracker(BillingDetails)}
                        />
                        <Route
                            exact
                            path="/reservation-success"
                            component={withTracker(ReservationSuccess)}
                        />
                        <Route
                            exact
                            path="/reservation-failed"
                            component={withTracker(ReservationFailure)}
                        />
                        <Route
                            exact
                            path={[
                                "/account/reservations",
                                "/account/user-details",
                            ]}
                            component={withTracker(MyAccount)}
                        />
                        <Route
                            exact
                            path="/account/reservations/:unique_reservation_id"
                            component={withTracker(ViewReservation)}
                        />
                        <Route exact path="/login">
                            <Redirect to="/" />
                        </Route>
                    </>
                ) : (
                    <>
                        <Route exact path="/login" component={withTracker(Login)} />
                        <Route path="*">
                            <Redirect to="/login" />
                        </Route>
                    </>
                )}
            </>
        </Switch>
    );
};
