import { Box, Modal } from '@material-ui/core';

import { CustomButton } from '../../Components/Buttons/formButton';
import MenuItem from "../../Components/MenuItem";
// import MenuItemCompact from '../../Components/MenuItemCompact';

function AddonModal({ addonModal, hideAddonModal, starters, getStarterButtonValues, onSelectingStarters}) {
  return (
      <Modal
          open={addonModal}
          onClose={hideAddonModal}
          aria-labelledby="addon-modal"
          aria-describedby="A modal to add addons"
      >
          <>
              <Box className={"addon-modal card"} style={{ marginTop: "30px" }}>
                  <h4 className="text-white text-center adobe-font">
                      Starters and Beverages
                  </h4>
                  {/* TODO: Change menu item component */}
                  {/* Remove addon state dependency */}
                  {/* Update the global starter quantity */}
                  <div style={{ overflow: "scroll", height: "88%" }}>
                      {starters.map((menuItem) => (
                          <MenuItem
                              key={menuItem.id}
                              menuItem={menuItem}
                              getMenuButtonValues={getStarterButtonValues}
                              onSelectingMenu={onSelectingStarters}
                          />
                      ))}
                  </div>
                  <div className="submit-booking-button">
                      <CustomButton
                          isPrimary={false}
                          size="large"
                          className="secondary-button border border-radius"
                          label="CONTINUE"
                          // style={{background: "#ca8e46", color: "white"}}
                          onClick={() => hideAddonModal()}
                      />
                  </div>
              </Box>
          </>
      </Modal>
  );
}

export default AddonModal