import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import './date.css';

export const CustomDate = ({
  id = "",
  label = "",
  className = "",
  dateFieldClass = "",
  required = false,
  disableToolbar = false,
  value,
  onChange = () => {},
  maxDate,
  minDate,
  errors = [],
  errorClassName = "",
}) => {
  return (
    <div className={`form-field ${className}`}>
      <label htmlFor={id} id={`id-${id}`}>
        <span>
          {label}
          {required ? <sup>*</sup> : ""}
        </span>
      </label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          className={dateFieldClass}
          autoOk
          format="dd/MM/yyyy"
          disableToolbar={disableToolbar}
          variant="inline"
          value={value}
          onChange={onChange}
          maxDate={maxDate}
          minDate={minDate}
        />
      </MuiPickersUtilsProvider>
      {errors.map((error, index) => (
        <span className={`error-message ${errorClassName}`} key={index}>
          {error}
        </span>
      ))}
    </div>
  );
};