import React from "react";
import { Desktop } from "./Pages/Desktop";
import { Header } from "./Pages/Header";
import { Footer } from "./Pages/Footer";
import { BrowserRouter } from "react-router-dom";

export const App = () => {
  return (
    <BrowserRouter>
      <Header/>
      <Desktop/>
      <Footer/>
    </BrowserRouter>
  );
};
