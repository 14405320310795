import { CHECK_RESERVATION, FETCH_ALL_RESERVATIONS, GET_TIME_SLOTS, LOAD_RESERVATION_BY_ID, SAVE_BILLING_DETAILS } from '../Types';

const initialState = {
    timeSlots: [],
    menuItems: [],
    reservationDetails: {},
    userDetails: {},
    reservationsList: [],
    viewReservation: {}
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TIME_SLOTS: 
            return {
                ...state,
                timeSlots: action.data.time_slots,
                menuItems: action.data.items
            };
        case CHECK_RESERVATION:
            return {
                ...state,
                reservationDetails: {
                    reservation_id: action.data.reservation_id,
                    unique_reservation_id: action.data.unique_reservation_id,
                    reservation_date: action.data.reservation_date,
                    no_of_seats: action.data.no_of_seats,
                    time_slot_id: action.data.time_slot_id,
                    items: action.data.items,
                    total: action.data.total
                }
            };
        case SAVE_BILLING_DETAILS: 
            return {
                ...state,
                userDetails: {
                    name: action.data.user.name,
                    email: action.data.user.email,
                    house: action.data.address.house,
                    street: action.data.address.street,
                    address: action.data.address.address,
                    city: action.data.address.city,
                    pincode: action.data.address.pincode,
                    dob: action.data.user.dob,
                    anniversary_date: action.data.user.anniversary_date,
                    phone: action.data.user.phone
                },
                reservationDetails: {
                    ...state.reservationDetails,                    
                    reservation_qr_code: action.data.reservation_qr_code,
                }
            };
        case FETCH_ALL_RESERVATIONS: 
            return {
                ...state,
                reservationsList: action.data
            };
        case LOAD_RESERVATION_BY_ID:
            return {
                ...state,
                viewReservation: action.data
            };
        default: return state;
    }
};

export default appReducer;