import React from 'react';
import { useHistory } from 'react-router-dom';
import { CustomButton } from "../../Components/Buttons/formButton";
import './reservationFailure.css';

export const ReservationFailure = () => {
  let history = useHistory();
  const goToHome = () => {
    history.push('/');
  };
  return (
    <div className='reservation-failure-wrapper'>
      <div className='card'>
        <h3 className='reservation-failure-title text-center'>
          Payment for reservation failed.
        </h3>
        <p className='reservation-failure-desc text-center'>
          Please try again!
        </p>
        <br/>
        <div className="home-button">
          <CustomButton
            isPrimary={true}
            size="large"
            className="primary-button border border-radius"
            label="GO TO HOME"
            onClick={() => goToHome()}
          />
        </div>
      </div>
    </div>
  );
};