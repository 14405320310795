import Grid from "@material-ui/core/Grid";
import veg_icon from "../Assets/Images/veg.png";
import non_veg_icon from "../Assets/Images/non_veg.png";
import { MenuButton } from "./Buttons/menuButton";
import React from "react";
import { backendUrl } from "../Utils";

const MenuItem = ({ menuItem, getMenuButtonValues, onSelectingMenu }) => {
    return (
        <div key={menuItem.id} className="menu-card">
            <div className="menu-item-header">
                <Grid container>
                    <Grid item xs={11}>
                        <h5>{(menuItem.name || "").toUpperCase()}</h5>
                    </Grid>
                    <Grid item xs={1} className="text-right">
                        <img
                            src={menuItem.is_veg ? veg_icon : non_veg_icon}
                            className="food-category"
                            alt={menuItem.is_veg ? "Veg" : "Non Veg"}
                        />
                    </Grid>
                </Grid>
            </div>
            <Grid container className="menu-item-description">
                <Grid item xs={4} sm={3}>
                    <img src={backendUrl + menuItem.image} style={{width: "90%"}} alt={menuItem.name}  className="rounded"/>
                </Grid>

                <Grid item xs={8} sm={9}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: menuItem.desc,
                        }}
                    />
                </Grid>
            </Grid>
            <div className="menu-item-footer">
                <Grid container>
                    <Grid item xs={4}>
                        <h5 className="text-left">
                            ₹{Math.floor(menuItem.price)}
                        </h5>
                    </Grid>
                    <Grid item xs={8} className="text-right">
                        <MenuButton
                            value={getMenuButtonValues(menuItem.id)}
                            callBack={(count) =>
                                onSelectingMenu(menuItem.id, count)
                            }
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default MenuItem;
